import { CloseCircleFilled } from '@ant-design/icons';
import React from 'react'; 
import './style.css';

const Error = () => {
    return (
        <div className="errorContainer">
            <CloseCircleFilled className="errorIcon" />
            <div className="errorText">Error Generating BOL</div>
            <div className="helpText">Please check your internet connection and contact Autosled support at 240-669-7242 for additional assistance.</div>
        </div>
    )
}

export default Error;
