import React from "react";
import "./style.css";

// component for rendering pickup and dropoff tab options in upper left corner.
// clicking will switch back and forth between showing pickup and dropoff BOL data in the BOL document
const Tabs = (props) => {
  const { inspectionType, setInspectionType, vehicleData } = props;

  const getActiveClass = (type) =>
    inspectionType === type ? "tabRow__tab--active" : "tabRow__tab--inactive";

  return (
    <>
      <div className="tabRow">
        {vehicleData?.[0]?.loadStatus > 2 && (
          <>
            <span
              onClick={() => setInspectionType("pickup")}
              className={`tabRow__tab ${getActiveClass("pickup")}`}
            >
              Pickup
            </span>
            <span
              onClick={() => setInspectionType("dropoff")}
              className={`tabRow__tab ${getActiveClass("dropoff")}`}
            >
              Dropoff
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default Tabs;
