import React from "react";
import './style.css';

const Photo = ({ url, visibility }) => {
  if (visibility === false) {
    return <img className="photo-grid-item-hidden" alt="" src={url} />
  }
  
  return <img className="photo-grid-item" alt="" src={url} />
};

export default Photo;
