import React from "react";
import Tabs from "../Tabs/Tabs";
import { PrinterOutlined } from "@ant-design/icons";
import "./style.css";

// Component that renders Tabs for pickup and dropoff, print icon in upper right corner, and a divider between the BOL document
const HeaderBar = (props) => {
  const { inspectionType, setInspectionType, vehicleData } = props;

  const handlePrintDocument = () => {
    window.print();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderBottom: ".2vw",
        borderBottomColor: "silver",
        borderBottomStyle: "solid",
      }}
      className="headerBar"
    >
      <Tabs
        inspectionType={inspectionType}
        setInspectionType={setInspectionType}
        vehicleData={vehicleData}
      />

      <PrinterOutlined className="printerIcon" onClick={handlePrintDocument} />
    </div>
  );
};

export default HeaderBar;
