import React from "react";

const DamageTypeAbbreviated = {
  scratch: "S",
  crack: "C",
  dent: "D",
  gouge: "G",
};

const DamageChip = ({ damageType }) => {
  const damageTypeLowerCase = damageType.toLowerCase();

  return (
    <span className={`damage-chip ${damageTypeLowerCase}`}>
      {DamageTypeAbbreviated[damageTypeLowerCase]}
    </span>
  );
};

export default DamageChip;
