import React from 'react';
import './style.css';

const Coordinate = ({ photoLocation, visibility }) => {
  if (visibility === false) {
    return <span className='photo-coordinate-text-hidden'>{photoLocation}</span>;
  }

  return <span className='photo-coordinate-text'>{photoLocation}</span>;
}

export default Coordinate;
