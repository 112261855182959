export const countryCodes = {
  43: "at",
  32: "be",
  359: "bg",
  385: "hr",
  357: "cy",
  420: "cz",
  45: "dk",
  372: "ee",
  358: "fi",
  33: "fr",
  49: "de",
  30: "gr",
  36: "hu",
  353: "ie",
  39: "it",
  371: "lv",
  370: "lt",
  352: "lu",
  356: "mt",
  31: "sx",
  48: "pl",
  351: "pt",
  40: "ro",
  421: "sk",
  386: "si",
  34: "es",
  46: "es",
  44: "gb",
  1204: "ca",
  1226: "ca",
  1236: "ca",
  1249: "ca",
  1250: "ca",
  1263: "ca",
  1289: "ca",
  1306: "ca",
  1343: "ca",
  1354: "ca",
  1365: "ca",
  1367: "ca",
  1368: "ca",
  1403: "ca",
  1416: "ca",
  1418: "ca",
  1431: "ca",
  1437: "ca",
  1438: "ca",
  1450: "ca",
  1468: "ca",
  1474: "ca",
  1506: "ca",
  1514: "ca",
  1519: "ca",
  1548: "ca",
  1579: "ca",
  1581: "ca",
  1584: "ca",
  1587: "ca",
  1604: "ca",
  1613: "ca",
  1639: "ca",
  1647: "ca",
  1672: "ca",
  1683: "ca",
  1705: "ca",
  1709: "ca",
  1742: "ca",
  1753: "ca",
  1778: "ca",
  1780: "ca",
  1782: "ca",
  1807: "ca",
  1819: "ca",
  1825: "ca",
  1867: "ca",
  1873: "ca",
  1902: "ca",
  1905: "ca",
  1201: "us",
  1202: "us",
  1203: "us",
  1205: "us",
  1206: "us",
  1207: "us",
  1208: "us",
  1209: "us",
  1210: "us",
  1212: "us",
  1213: "us",
  1214: "us",
  1215: "us",
  1216: "us",
  1217: "us",
  1218: "us",
  1219: "us",
  1220: "us",
  1223: "us",
  1224: "us",
  1225: "us",
  1228: "us",
  1229: "us",
  1231: "us",
  1234: "us",
  1239: "us",
  1240: "us",
  1248: "us",
  1251: "us",
  1252: "us",
  1253: "us",
  1254: "us",
  1256: "us",
  1260: "us",
  1262: "us",
  1267: "us",
  1269: "us",
  1270: "us",
  1272: "us",
  1276: "us",
  1279: "us",
  1281: "us",
  1301: "us",
  1302: "us",
  1303: "us",
  1304: "us",
  1305: "us",
  1307: "us",
  1308: "us",
  1309: "us",
  1310: "us",
  1312: "us",
  1313: "us",
  1314: "us",
  1315: "us",
  1316: "us",
  1317: "us",
  1318: "us",
  1319: "us",
  1320: "us",
  1321: "us",
  1323: "us",
  1325: "us",
  1326: "us",
  1330: "us",
  1331: "us",
  1332: "us",
  1334: "us",
  1336: "us",
  1337: "us",
  1339: "us",
  1341: "us",
  1346: "us",
  1347: "us",
  1350: "us",
  1351: "us",
  1352: "us",
  1360: "us",
  1361: "us",
  1363: "us",
  1364: "us",
  1380: "us",
  1385: "us",
  1386: "us",
  1401: "us",
  1402: "us",
  1404: "us",
  1405: "us",
  1406: "us",
  1407: "us",
  1408: "us",
  1409: "us",
  1410: "us",
  1412: "us",
  1413: "us",
  1414: "us",
  1415: "us",
  1417: "us",
  1419: "us",
  1423: "us",
  1424: "us",
  1425: "us",
  1430: "us",
  1432: "us",
  1434: "us",
  1435: "us",
  1440: "us",
  1442: "us",
  1443: "us",
  1445: "us",
  1447: "us",
  1448: "us",
  1458: "us",
  1463: "us",
  1464: "us",
  1469: "us",
  1470: "us",
  1472: "us",
  1475: "us",
  1478: "us",
  1479: "us",
  1480: "us",
  1484: "us",
  1501: "us",
  1502: "us",
  1503: "us",
  1504: "us",
  1505: "us",
  1507: "us",
  1508: "us",
  1509: "us",
  1510: "us",
  1512: "us",
  1513: "us",
  1515: "us",
  1516: "us",
  1517: "us",
  1518: "us",
  1520: "us",
  1530: "us",
  1531: "us",
  1534: "us",
  1539: "us",
  1540: "us",
  1541: "us",
  1551: "us",
  1557: "us",
  1559: "us",
  1561: "us",
  1562: "us",
  1563: "us",
  1564: "us",
  1567: "us",
  1570: "us",
  1571: "us",
  1572: "us",
  1573: "us",
  1574: "us",
  1575: "us",
  1580: "us",
  1582: "us",
  1585: "us",
  1586: "us",
  1601: "us",
  1602: "us",
  1603: "us",
  1605: "us",
  1606: "us",
  1607: "us",
  1608: "us",
  1609: "us",
  1610: "us",
  1612: "us",
  1614: "us",
  1615: "us",
  1616: "us",
  1617: "us",
  1618: "us",
  1619: "us",
  1620: "us",
  1623: "us",
  1626: "us",
  1628: "us",
  1629: "us",
  1630: "us",
  1631: "us",
  1636: "us",
  1640: "us",
  1641: "us",
  1646: "us",
  1650: "us",
  1651: "us",
  1656: "us",
  1657: "us",
  1659: "us",
  1660: "us",
  1661: "us",
  1662: "us",
  1667: "us",
  1669: "us",
  1678: "us",
  1680: "us",
  1681: "us",
  1682: "us",
  1689: "us",
  1701: "us",
  1702: "us",
  1703: "us",
  1704: "us",
  1706: "us",
  1707: "us",
  1708: "us",
  1712: "us",
  1713: "us",
  1714: "us",
  1715: "us",
  1716: "us",
  1717: "us",
  1718: "us",
  1719: "us",
  1720: "us",
  1724: "us",
  1725: "us",
  1726: "us",
  1727: "us",
  1731: "us",
  1732: "us",
  1734: "us",
  1737: "us",
  1740: "us",
  1743: "us",
  1747: "us",
  1754: "us",
  1757: "us",
  1760: "us",
  1762: "us",
  1763: "us",
  1765: "us",
  1769: "us",
  1770: "us",
  1771: "us",
  1772: "us",
  1773: "us",
  1774: "us",
  1775: "us",
  1779: "us",
  1781: "us",
  1785: "us",
  1786: "us",
  1800: "us",
  1801: "us",
  1802: "us",
  1803: "us",
  1804: "us",
  1805: "us",
  1806: "us",
  1808: "us",
  1810: "us",
  1812: "us",
  1813: "us",
  1814: "us",
  1815: "us",
  1816: "us",
  1817: "us",
  1818: "us",
  1820: "us",
  1826: "us",
  1828: "us",
  1830: "us",
  1831: "us",
  1832: "us",
  1833: "us",
  1835: "us",
  1838: "us",
  1839: "us",
  1840: "us",
  1843: "us",
  1845: "us",
  1847: "us",
  1848: "us",
  1850: "us",
  1854: "us",
  1855: "us",
  1856: "us",
  1857: "us",
  1858: "us",
  1859: "us",
  1860: "us",
  1862: "us",
  1863: "us",
  1864: "us",
  1865: "us",
  1870: "us",
  1872: "us",
  1877: "us",
  1878: "us",
  1888: "us",
  1901: "us",
  1903: "us",
  1904: "us",
  1906: "us",
  1907: "us",
  1908: "us",
  1909: "us",
  1910: "us",
  1912: "us",
  1913: "us",
  1914: "us",
  1915: "us",
  1916: "us",
  1918: "us",
  1919: "us",
  1920: "us",
  1925: "us",
  1928: "us",
  1931: "us",
  1936: "us",
  1937: "us",
  1940: "us",
  1941: "us",
  1949: "us",
  1951: "us",
  1952: "us",
  1954: "us",
  1956: "us",
  1970: "us",
  1972: "us",
  1973: "us",
  1978: "us",
  1980: "us",
  1985: "us",
  1979: "us",
  1989: "us",
  93: "af",
  355: "al",
  213: "dz",
  376: "ad",
  244: "ao",
  1268: "ag",
  54: "ar",
  374: "am",
  297: "aw",
  61: "au",
  994: "az",
  1242: "bs",
  973: "bh",
  880: "bd",
  1246: "bb",
  375: "by",
  501: "bz",
  229: "bj",
  975: "bt",
  591: "bo",
  387: "ba",
  267: "bw",
  55: "br",
  246: "io",
  673: "bn",
  226: "bf",
  257: "bi",
  855: "kh",
  237: "cm",
  238: "cv",
  599: "bq",
  236: "cf",
  235: "td",
  56: "cl",
  86: "cn",
  57: "co",
  269: "km",
  243: "cd",
  242: "cg",
  506: "cr",
  225: "ci",
  53: "cu",
  253: "dj",
  1767: "dm",
  1809: "do",
  1829: "do",
  1849: "do",
  593: "ec",
  20: "eg",
  503: "sv",
  240: "gq",
  291: "er",
  251: "et",
  679: "fj",
  594: "gf",
  689: "pf",
  241: "ga",
  220: "gm",
  995: "ge",
  233: "gh",
  1473: "gd",
  590: "gp",
  1671: "gu",
  502: "gt",
  224: "gn",
  245: "gw",
  592: "gy",
  509: "ht",
  504: "hn",
  852: "hk",
  354: "is",
  91: "in",
  62: "id",
  98: "ir",
  964: "iq",
  972: "il",
  1876: "jm",
  81: "jp",
  962: "jo",
  7310: "kz",
  7311: "kz",
  7312: "kz",
  7313: "kz",
  7315: "kz",
  7318: "kz",
  7321: "kz",
  7324: "kz",
  7325: "kz",
  7326: "kz",
  7327: "kz",
  7336: "kz",
  7717: "kz",
  7736: "kz",
  254: "ke",
  686: "ki",
  383: "xk",
  965: "kw",
  996: "kg",
  856: "la",
  961: "lb",
  266: "ls",
  231: "lr",
  218: "ly",
  423: "li",
  853: "mo",
  389: "mk",
  261: "mg",
  265: "mw",
  60: "my",
  960: "mv",
  223: "ml",
  692: "mh",
  596: "mq",
  222: "mr",
  230: "mu",
  691: "fm",
  373: "md",
  377: "mc",
  976: "mn",
  382: "me",
  212: "ma",
  258: "mz",
  95: "mm",
  264: "na",
  674: "nr",
  977: "np",
  687: "nc",
  64: "nz",
  505: "ni",
  227: "ne",
  234: "ng",
  850: "kp",
  47: "no",
  968: "om",
  92: "pk",
  680: "pw",
  970: "ps",
  507: "pa",
  675: "pg",
  595: "py",
  51: "pe",
  63: "ph",
  1787: "pr",
  1939: "pr",
  974: "qa",
  262: "re",
  7: "ru",
  250: "rw",
  1869: "kn",
  1758: "lc",
  1784: "vc",
  685: "ws",
  378: "sm",
  239: "st",
  966: "sa",
  221: "sn",
  381: "rs",
  248: "sc",
  232: "sl",
  65: "sg",
  677: "sb",
  252: "so",
  27: "za",
  82: "kr",
  211: "ss",
  94: "lk",
  249: "sd",
  597: "sr",
  268: "sz",
  41: "ch",
  963: "sy",
  886: "tw",
  992: "tj",
  255: "tz",
  66: "th",
  670: "tl",
  228: "tg",
  676: "to",
  1868: "tt",
  216: "tn",
  90: "tr",
  993: "tm",
  688: "tv",
  256: "ug",
  380: "ua",
  971: "ae",
  598: "uy",
  998: "uz",
  678: "vu",
  58: "ve",
  84: "vn",
  967: "ye",
  260: "zm",
  263: "zw",
};
