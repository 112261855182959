import "./App.less";
import React, { useState, useEffect } from "react";
import { getVehicleInfo, getVehicleDamages } from "./api";
import BOL from "./components/BOL/BOL";
import HeaderBar from "./components/HeaderBar/HeaderBar";
import Loading from "./components/Loading/Loading";
import Error from "./components/Error/Error";

// Main app component, fetches the vehicle data to create the BOL and returns a HeaderBar component and a BOL component to create the page.
// Handles displaying a placeholder loading page and error page if an error happens getting the vehicle data, handled by Loading and Error components respectively.

const App = () => {
  const [vehicleData, setVehicleData] = useState(null);
  const [inspectionType, setInspectionType] = useState("pickup");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Fetch vehicle data for BOL. App looks for a vehicleId in the params to send the request to the api.
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const vehicleId = urlParams.get("vehicleId");
    const inspectionType = urlParams.get("inspectionType");

    if (inspectionType && (inspectionType === 'pickup' || inspectionType === 'dropoff')) {
      setInspectionType(inspectionType);
    }

    const fetchData = async () => {
      const vehicleInfo = getVehicleInfo(vehicleId);
      const vehicleDamages = getVehicleDamages(vehicleId);
      const vehicleData = await Promise.all([vehicleInfo, vehicleDamages]);

      // console.log(vehicleData);

      if (vehicleData[0].length === 0 || vehicleData[1].length === 0) {
        // Could not find vehicle data, set error
        setError(true);
      } else {
        // Vehicle data retrieved, set state to render document
        setVehicleData(vehicleData);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (vehicleData) {
      // Once vehicle data is updated, we are finished loading.
      setLoading(false);
    }
  }, [vehicleData]);

  if (error) {
    return <Error />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <HeaderBar
        inspectionType={inspectionType}
        setInspectionType={setInspectionType}
        vehicleData={vehicleData}
      />
      <BOL inspectionType={inspectionType} vehicleData={vehicleData} />
    </>
  );
};

export default App;
