import React, { useState, useEffect } from 'react';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/Spinner.css';
import './style.css';

const Loading = () => {
    const [spinnerSize, setSpinnerSize] = useState(window.innerWidth / 20);
    const handleResize = () => {
        setSpinnerSize(window.innerWidth > 700 ? window.innerWidth / 20 : window.innerWidth / 10);
    }

    // add resize handler to adjust size of spinner when resizing screen
    useEffect(() => {
        window.onresize = handleResize;
        return () => {
            window.onresize = null;
        }
    }, []);

    return (
        <div className="loadingContainer">
            <Spinner size={spinnerSize} color="#2d6ac4" />
            <div className="loadingText">Generating BOL</div>
        </div> 
    );
}

export default Loading;
