const getApiBaseUrl = () => {
    if (process.env.REACT_APP_ENV === 'local') {
       return "https://api.autosled-dev.com";
    } 
    
    if (process.env.REACT_APP_ENV === 'development') {        
        return "https://api.autosled-dev.com";
    }

    if (process.env.REACT_APP_ENV === 'testing') {        
      return "https://api.autosled-testing.com";
    }

    return "https://api.autosled.com";
}

export const getVehicleInfo = async (vehicleId) => {
    try {
        const res = await fetch(`${getApiBaseUrl()}/api/Vehicles/${vehicleId}/Anon`);

        if (res.status >= 400 && res.status < 600) {
            throw new Error('Bad response from server');
        }

        const data = await res.json();
        return data;
    } catch(err) {
        console.error('Error fetching data', err);
        return [];
    }
}

export const getVehicleDamages = async (vehicleId) => {
    try {
        const res = await fetch(`${getApiBaseUrl()}/api/Transporter/Vehicles/getVehicleDamages?vehicleId=${vehicleId}`,{
            headers: {
              'Content-Type': 'application/json'   // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            //body: JSON.stringify(vehicleId)        // body data type must match "Content-Type" header
            });

        if (res.status >= 400 && res.status < 600) {
            throw new Error('Bad response from server');
        }   
        
        const data = await res.json();
        return data;
    } catch(err) {
        console.error('Error fetching data', err);
        return [];
    }
}