/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AutosledLogo from "./Images/AutosledLogo";
import FrontDiagramImage from "./Images/FrontDiagramImage";
import RearDiagramImage from "./Images/RearDiagramImage";
import PassengerDiagramImage from "./Images/PassengerDiagramImage";
import DriverDiagramImage from "./Images/DriverDiagramImage";
import initialState from "./initialState";
import "./style.css";
import DamageChip from "../DamageChip/DamageChip";
import Photo from "../Photo/Photo";
import moment from "moment";
import Coordinate from "../Coordinate/Coordinate";
import { countryCodes } from "../../countryCodes";
// import html2pdf from "html2pdf.js";

const capitalize = (str) => {
  if (!str) return str;
  let firstLetter = str[0];

  return firstLetter.toUpperCase() + str.slice(1);
};

const DamageTypes = {
  0: "None",
  1: "Scratch",
  2: "Crack",
  3: "Dent",
  4: "Gouge",
};

const DamageTypesEnum = {
  None: 0,
  Scratch: 1,
  Crack: 2,
  Dent: 3,
  Gouge: 4,
};

// const DamageColors = {
//   None: "rgba(0, 128, 0, 1)", // green
//   Scratch: "rgba(128, 128, 0, 1)", // olive
//   Crack: "rgba(255, 215, 0, 1)", // yellow
//   Dent: "rgba(255, 165, 0, 1)", // orange
//   Gouge: "rgba(255, 0, 0, 1)", // red
// };

// const DamageColorsTransparent = {
//   0: "rgba(0, 128, 0, 0.5)", // green
//   1: "rgba(128, 128, 0, 0.5)", // olive
//   2: "rgba(255, 215, 0, 0.5)", // yellow
//   3: "rgba(255, 165, 0, 0.5)", // orange
//   4: "rgba(255, 0, 0, 0.5)", // red
// };

const getPhotoFilenames = (inspectionType, data) => {
  const fileNames = data[`${inspectionType}Urls`].map(
    (item) => item.filenameCloudFrontUrl
  );
  return fileNames;
};

const parsePhoneNumber = (number) => {
  if (!number) {
    return {
      countryCode: null,
      areaCode: null,
      phoneNumber: null,
      isoCode: "us",
    };
  }

  if (number.startsWith("+")) {
    number = number.slice(1, number.length);
  }

  number = number
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll(" ", "")
    .replaceAll("-", "");

  const minCountryCodeLength = 1;
  const maxCountryCodeLength = 4;

  for (let i = maxCountryCodeLength; i >= minCountryCodeLength; i--) {
    let countryCode = number.slice(0, i);

    if (countryCode.length < i) {
      // number is not long enough to consider this length as a country code
      continue;
    }

    let isoCode = countryCodes[countryCode];

    // last char to check, if it's 1 it means the area code isn't valid
    // let's assume it's a us number
    if (i === 1 && countryCode === "1") {
      countryCode = number.slice(0, 4);
      isoCode = "US";
    }

    if (isoCode) {
      let areaCode;

      if (countryCode.startsWith("1")) {
        areaCode = countryCode.slice(1, 4);
        countryCode = "1";
      } else {
        areaCode = number.slice(i, i + 3);
      }

      return {
        countryCode,
        areaCode,
        phoneNumber: number.slice(
          countryCode.length + areaCode.length,
          number.length
        ),
        isoCode,
      };
    }
  }

  return {
    countryCode: null,
    areaCode: null,
    phoneNumber: null,
    isoCode: "us",
  };
};

const formatPhoneNumber = (num, extension) => {
  if (!num) {
    return "";
  }

  const parsed = parsePhoneNumber(num);
  const { countryCode, areaCode, phoneNumber } = parsed;

  if (!countryCode || !areaCode || !phoneNumber) {
    return num;
  }

  return `+${countryCode} (${areaCode})-${phoneNumber.slice(
    0,
    3
  )}-${phoneNumber.slice(3, phoneNumber.length)}${
    extension ? `;${extension}` : ""
  }`;
};

const getShortenedLoadId = (fullLoadId) => {
  if (!fullLoadId) return "N/A";
  return fullLoadId.split("-")[0].toUpperCase();
};

const vehicleTypeToString = (type) => {
  if (type === 1) return "Car";
  if (type === 2) return "Truck";
  if (type === 3) return "SUV";
  if (type === 4) return "Van";
  if (type === 5) return "Other";
  return "Not Specified";
};

// BOL document component, renders printable BOL document.
// vehicleData is an Array of length 2.
// vehicleData[0] contains general vehicle data
// vehicleData[1] contains vehicle inspection damage data
// inspection type is a String equal to either 'pickup' or 'dropoff'
const BOL = (props) => {
  const { vehicleData, inspectionType } = props;

  //console.log("vehicleData");
  //console.log(vehicleData);
  //console.log(inspectionType);

  const [documentState, setDocumentState] = useState({ ...initialState });

  const getDamageChips = (sectionName, sectionNumber) => {
    // damage lists are zero indexed
    const damageList =
      documentState[inspectionType].inspection[sectionName][sectionNumber - 1];
    let index = 0;

    if (damageList[0] === "None") return "No Damage";

    return damageList.map((damageType) => (
      <DamageChip key={index++} damageType={damageType} />
    ));
  };

  const sortDamageList = (damageList) => {
    const sorter = (e1, e2) => {
      if (DamageTypesEnum[e1] < DamageTypesEnum[e2]) {
        return -1;
      }

      if (DamageTypesEnum[e1] === DamageTypesEnum[e2]) {
        return 0;
      }

      if (DamageTypesEnum[e1] > DamageTypesEnum[e2]) {
        return 1;
      }
    };

    for (let i = 0; i < Object.keys(damageList).length; i++) {
      damageList[i].sort(sorter);
    }

    return damageList;
  };

  const generateDamageList2 = (listName, data, initialDamageList) => {
    // listName will go through 'Front', 'Rear', 'Driverside', 'Passengerside'
    // inspectionType will be 'pickup' or 'dropoff'
    // dataListName references the list of damages given back by api
    // pickupFront, dropoffFront, etc...
    const dataListName = `${inspectionType}${listName}`;

    const generatedDamageList = data[dataListName].reduce(
      (damageList, curr) => {
        // refers to numbered section in diagram, 0 - 3 for front and rear, 0 - 7 for driver and passenger
        const sectionIndex = curr.vehicleSection.label[1] - 1;

        // refers to keys in damage types object, 0 - 4
        const damageTypeIndex = curr.damage;

        // add damage string to correct section index in list of damages
        damageList[sectionIndex].push(DamageTypes[damageTypeIndex]);

        return damageList;
      },
      initialDamageList
    );

    sortDamageList(generatedDamageList);

    return generatedDamageList;
  };

  const areThereTransitDamages = (type) => {
    if (type === "pickup") {
      return true;
    }

    const inspection = documentState["dropoff"].inspection;

    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < inspection.Driverside[i].length; j++) {
        if (inspection.Driverside[i][j] !== "None") {
          return true;
        }
      }
      for (let j = 0; j < inspection.Passengerside[i].length; j++) {
        if (inspection.Passengerside[i][j] !== "None") {
          return true;
        }
      }
    }

    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < inspection.Front[i].length; j++) {
        if (inspection.Front[i][j] !== "None") {
          return true;
        }
      }
      for (let j = 0; j < inspection.Rear[i].length; j++) {
        if (inspection.Rear[i][j] !== "None") {
          return true;
        }
      }
    }
    return false;
  };

  const getContactPrinted = (inspectionType) => {
    let signatureFilename =
      vehicleData[1][`${inspectionType}Signatures`]?.[0]?.filenameCloudFrontUrl;
    const loadId = vehicleData[0].loadId;

    if (signatureFilename) {
      signatureFilename = signatureFilename.replace(".jpg", "");
      if (
        process.env.REACT_APP_ENV === "development" ||
        process.env.REACT_APP_ENV === "local"
      ) {
        signatureFilename = signatureFilename.replace(
          "https://autosleddev.blob.core.windows.net/dropoff/signatures/signature_",
          ""
        );
      } else {
        signatureFilename = signatureFilename.replace(
          "https://autosled.blob.core.windows.net/dropoff/signatures/signature_",
          ""
        );
      }
      signatureFilename = signatureFilename.replace(loadId + "_", "");

      return signatureFilename;
    }
  };

  const getContactSignature = (inspectionType) => {
    const signatureFilename =
      vehicleData[1][`${inspectionType}Signatures`]?.[0]?.filenameCloudFrontUrl;

    return signatureFilename;
  };

  const getInspection = (inspectionType, data) => {
    // const inspection = {...initialState[inspectionType].inspection};
    const inspection = {
      Front: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      Rear: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      Driverside: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      },
      Passengerside: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      },
    };

    Object.keys(inspection).forEach((listName) => {
      inspection[listName] = generateDamageList2(
        listName,
        data,
        inspection[listName]
      );
    });

    return inspection;
  };

  const formatDate = (dateString) => {
    const momentDate = moment.utc(dateString).local();

    if (momentDate.isValid()) {
      return momentDate.format("MM/DD/YYYY");
    }

    return "Pending";
  };

  const formatDateTime = (dateString) => {
    const momentDate = moment.utc(dateString).local();

    if (momentDate.isValid()) {
      return momentDate.format("MM/DD/YYYY, h:mm A");
    }

    return "Pending";
  };

  // get the lat and long coordinates from the photo filename
  const parsePhotoLocation = (photoFilename) => {
    // currently reliant on vehicleUrls format
    // Example filename: https://d17m9clo05sm4z.cloudfront.net/bol-images-pickup/vehicle_8298dcda_pickup-cornerFD_upload_252281af_lat:47.7196,long:-122.2109_.jpg?Policy=&Signature=&Key-Pair-Id=
    const indexStartLat = photoFilename.indexOf("lat:") + 4;
    const indexStartLong = photoFilename.indexOf("long:") + 5;

    // indexOf will return -1 if 'lat:' or 'long:' strings not found in filename
    if (indexStartLat === 3 || indexStartLong === 4)
      return "No lat/long available";

    let lat = "";
    let long = "";
    let index = indexStartLat;

    // add digits to lat/long strings

    while (photoFilename[index] !== ".") {
      lat += photoFilename[index];
      index++;
    }

    lat += ".";
    index++;

    while (photoFilename[index] !== ",") {
      lat += photoFilename[index];
      index++;
    }

    index = indexStartLong;

    while (photoFilename[index] !== ".") {
      long += photoFilename[index];
      index++;
    }

    long += ".";
    index++;

    while (photoFilename[index] !== "_") {
      long += photoFilename[index];
      index++;
    }

    return `${lat}, ${long}`;
  };

  const renderPhotogrid = () => {
    const photoFilenameList = documentState[inspectionType].photos;
    let photoUrl;
    let photoLocation;
    let nextPhotoRowContents = [];
    let nextCoordinateRowContents = [];
    let jsxOutput = [];
    let counter = 0;
    let rowNum = 0;

    for (let photoFilename of photoFilenameList) {
      if (counter === 3) {
        jsxOutput.push(
          <div key={rowNum}>
            {nextPhotoRowContents}
            {nextCoordinateRowContents}
          </div>
        );

        nextPhotoRowContents = [];
        nextCoordinateRowContents = [];
        counter = 0;
      }

      photoUrl = photoFilename;
      photoLocation = parsePhotoLocation(photoUrl);
      nextPhotoRowContents.push(
        <Photo key={photoUrl} url={photoUrl} visibility={true} />
      );
      nextCoordinateRowContents.push(
        <Coordinate
          key={counter}
          photoLocation={photoLocation}
          visibility={true}
        />
      );
      counter++;
      rowNum++;
    }

    // adding additional hidden photos to take up space and make even grid so that coords and photos on bottom row line up
    if (counter > 0) {
      while (counter < 3) {
        nextPhotoRowContents.push(
          <Photo key={photoUrl + counter} url={photoUrl} visibility={false} />
        );
        nextCoordinateRowContents.push(
          <Coordinate
            key={counter}
            photoLocation={photoLocation}
            visibility={false}
          />
        );
        counter++;
      }
    }

    jsxOutput.push(
      <div key={rowNum}>
        {nextPhotoRowContents}
        {nextCoordinateRowContents}
      </div>
    );

    return jsxOutput;
  };

  useEffect(() => {
    const updateDocumentState = () => {
      // generate state for document whenever vehicleData changes
      setDocumentState((prevState) => {
        const newState = {
          general: {
            loadId: getShortenedLoadId(vehicleData[0].loadId),
            loadStatus: vehicleData[0].loadStatus,
            shipperName: vehicleData[0].shipperName,
            vehicle: {
              year: vehicleData[0].year,
              make: vehicleData[0].make,
              model: vehicleData[0].model,
              type: vehicleTypeToString(vehicleData[0].vehicleType),
              color: vehicleData[0].color
                ? vehicleData[0].color
                : "Not Specified",
              vin: vehicleData[0].vin,
              odometer:
                inspectionType === "pickup"
                  ? vehicleData[0].odometerPickUp
                  : inspectionType === "dropoff"
                  ? vehicleData[0].odometerDropOff
                  : "Not Specified",
            },
            driver: {
              company: vehicleData[0].transporterEntity.name,
              companyPhone: vehicleData[0].transporterEntity.phoneNumber1,
              usDot: vehicleData[0].transporter.dotNumber,
            },
            origin: {
              name: vehicleData[0].pickup.name,
              address: vehicleData[0].pickup.address,
              city: vehicleData[0].pickup.city,
              state: vehicleData[0].pickup.state,
              zip: vehicleData[0].pickup.zipcode,
              contactName: vehicleData[0].pickup.contactName,
              contactPhone: vehicleData[0].pickup.contactPhone,
            },
            destination: {
              name: vehicleData[0].dropoff.name,
              address: vehicleData[0].dropoff.address,
              city: vehicleData[0].dropoff.city,
              state: vehicleData[0].dropoff.state,
              zip: vehicleData[0].dropoff.zipcode,
              contactName: vehicleData[0].dropoff.contactName,
              contactPhone: vehicleData[0].dropoff.contactPhone,
            },
          },
          pickup: {
            contactSignature: getContactSignature("pickup"),
            contactPrinted: vehicleData[0].pickupCustomerName,
            date: formatDate(vehicleData[0].pickup.date),
            dateTime: formatDateTime(vehicleData[0].pickup.date),
            vehicleNotes: vehicleData[0].pickupNote
              ? vehicleData[0].pickupNote
              : "No notes provided by driver at pickup.",
            loadNotes: vehicleData[0].loadNotesAtPickup
              ? vehicleData[0].loadNotesAtPickup
              : "No notes provided by driver at pickup.",
            photos: getPhotoFilenames("pickup", vehicleData[0]),
            inspection: getInspection("pickup", vehicleData[1]),
            driver: {
              name: `${vehicleData[0].pickup.transporterUser.firstName} ${vehicleData[0].pickup.transporterUser.lastName}`,
              phone: vehicleData[0].pickup.transporterUser.phoneNumber1,
              signatureUrl:
                vehicleData[0].pickup.transporterUser.signatureCloudFrontUrl,
            },
          },

          dropoff: {
            contactSignature: getContactSignature("dropoff"),
            contactPrinted:
              vehicleData[0].customerName === null
                ? getContactPrinted("dropoff")
                : vehicleData[0].customerName,
            date: formatDate(vehicleData[0].dropoff.date),
            dateTime: formatDateTime(vehicleData[0].dropoff.date),
            vehicleNotes: vehicleData[0].dropoffNote
              ? vehicleData[0].dropoffNote
              : "No notes provided by driver at dropoff.",
            loadNotes: vehicleData[0].loadNotesAtDropoff
              ? vehicleData[0].loadNotesAtDropoff
              : "No notes provided by driver at dropoff.",
            photos: getPhotoFilenames("dropoff", vehicleData[0]),
            inspection: getInspection("dropoff", vehicleData[1]),
            driver: {
              name: `${vehicleData[0].dropoff.transporterUser.firstName} ${vehicleData[0].dropoff.transporterUser.lastName}`,
              phone: vehicleData[0].dropoff.transporterUser.phoneNumber1,
              signatureUrl:
                vehicleData[0].dropoff.transporterUser.signatureCloudFrontUrl,
            },
          },
        };

        return newState;
      });
    };
    updateDocumentState();
  }, [inspectionType, vehicleData]);

  useEffect(() => {
    const colorDiagramSections = () => {
      if (areThereTransitDamages(inspectionType) === true) {
        const sides = ["Front", "Rear", "Driverside", "Passengerside"];

        for (let side of sides) {
          let sideLowerCase = side.toLowerCase();
          let upperBound =
            sideLowerCase === "front" || sideLowerCase === "rear" ? 4 : 8;

          for (
            let sectionNumber = 1;
            sectionNumber <= upperBound;
            sectionNumber++
          ) {
            const damageList =
              documentState[inspectionType].inspection[side][sectionNumber - 1];
            if (damageList.length === 0) return;
            const mostSevereDamageType =
              damageList[damageList.length - 1].toLowerCase();

            let element = document.querySelector(
              `.${sideLowerCase}-${sectionNumber}`
            );
            element.style.backgroundColor = `var(--${mostSevereDamageType})`;
          }
        }
      }
    };

    //console.log("updated document");
    //console.log(documentState);
    colorDiagramSections();
  }, [documentState]);

  // const generate = () => {
  //   var element = document.getElementById("pdf-doc");
  //   var opt = {
  //     margin: [10, 10, 10, 10],
  //     filename: "myfile.pdf",
  //     image: { type: "jpeg", quality: 0.5 },
  //     html2canvas: { scale: 10 },
  //     jsPDF: {
  //       orientation: "p",
  //       unit: "mm",
  //       format: "a4",
  //       putOnlyUsedFonts: true,
  //       floatPrecision: 16, // or "smart", default is 16
  //     },
  //   };
  //   //html2pdf().set(opt).from(element).save();
  //   const pdf = html2pdf(element).set(opt);
  //   pdf.toImg().save();
  // };

  const bolDocument = (
    <div id="pdf-doc">
      {/* <!-- BEGIN HEADER --> */}

      <div className="header row">
        <div className="header__left col">
          <div className="header__title">Bill of Lading</div>
          <div className="header__subtitle">
            {capitalize(inspectionType)} Inspection:{" "}
            {documentState[inspectionType].date}
          </div>
          <span className="header__subtitle">
            <span className="header__label">Load ID:&nbsp;&nbsp;</span>
            <span className="header__data">{documentState.general.loadId}</span>
          </span>
          <span className="header__subtitle">
            <span className="header__label">Shipper:&nbsp;&nbsp;&nbsp;</span>
            <span className="header__data">
              {documentState.general.shipperName}
            </span>
          </span>
        </div>

        <div className="header__right row">
          {/* AUTOSLED LOGO IMAGE */}
          <span className="logo-container">
            <AutosledLogo />
          </span>

          <div className="col">
            <span className="header__address-line">Autosled, Inc</span>
            <span className="header__address-line">6010 Executive Blvd</span>
            <span className="header__address-line">Rockville, MD, 20852</span>
            <span className="header__address-line">240-880-2702</span>
          </div>
        </div>
      </div>

      {/* <!-- END HEADER --> */}

      {/* <!-- BEGIN INFO --> */}
      <div className="info-grid">
        <div className="info-grid-item section-container">
          <div className="section-header">Vehicle</div>

          {/* <!-- year, make, model, vin, type, color, odometer reading--> */}
          <div className="info-table row">
            {/* <!-- labels --> */}
            <div className="info-table__col col">
              <div className="info-table__item info-table__item--label">
                Year
              </div>
              <div className="info-table__item info-table__item--label">
                Make
              </div>
              <div className="info-table__item info-table__item--label">
                Model
              </div>
              <div className="info-table__item info-table__item--label">
                Type
              </div>
              <div className="info-table__item info-table__item--label">
                Color
              </div>
              <div className="info-table__item info-table__item--label">
                VIN #
              </div>
              <div className="info-table__item info-table__item--label">
                Odometer
              </div>
            </div>

            {/* <!-- data --> */}
            <div className="info-table__col col">
              <div className="info-table__item info-table__item--data">
                {documentState.general.vehicle.year}
              </div>
              <div className="info-table__item info-table__item--data">
                {documentState.general.vehicle.make}
              </div>
              <div className="info-table__item info-table__item--data">
                {documentState.general.vehicle.model}
              </div>
              <div className="info-table__item info-table__item--data">
                {documentState.general.vehicle.type}
              </div>
              <div className="info-table__item info-table__item--data">
                {documentState.general.vehicle.color}
              </div>
              <div className="info-table__item info-table__item--data">
                {documentState.general.vehicle.vin}
              </div>
              <div className="info-table__item info-table__item--data">
                {documentState.general.vehicle.odometer}
              </div>
            </div>
          </div>
        </div>
        <div className="info-grid-item section-container">
          <div className="section-header">Transporter</div>

          {/* <!-- name, phone, company, compnay phone, us dot#, signature--> */}
          <div className="info-table row">
            {/* <!-- labels --> */}
            <div className="info-table__col col">
              <div className="info-table__item info-table__item--label">
                Company Name
              </div>
              <div className="info-table__item info-table__item--label">
                Company Phone
              </div>
              <div className="info-table__item info-table__item--label">
                Driver Name
              </div>
              <div className="info-table__item info-table__item--label">
                Driver Phone
              </div>
              <div className="info-table__item info-table__item--label">
                US DOT #
              </div>
            </div>

            {/* <!-- data --> */}
            <div className="info-table__col col">
              <div className="info-table__item info-table__item--data">
                {documentState.general.driver.company}
              </div>
              <div className="info-table__item info-table__item--data">
                {formatPhoneNumber(documentState.general.driver.companyPhone)}
              </div>
              <div className="info-table__item info-table__item--data">
                {inspectionType === "pickup"
                  ? documentState.pickup.driver.name
                  : documentState.dropoff.driver.name}
              </div>
              <div className="info-table__item info-table__item--data">
                {formatPhoneNumber(
                  inspectionType === "pickup"
                    ? documentState.pickup.driver.phone
                    : documentState.dropoff.driver.phone
                )}
              </div>
              <div className="info-table__item info-table__item--data">
                {documentState.general.driver.usDot}
              </div>
            </div>
          </div>
        </div>
        <div className="info-grid-item section-container">
          <div className="section-header">Origin</div>

          <div className="info-box margin-bottom">
            <div>{documentState.general.origin.name}</div>
            <div>
              {documentState.general.origin.address} <br />
              {`${documentState.general.origin.city}, ${documentState.general.origin.state} ${documentState.general.origin.zip}`}
            </div>
          </div>

          <div className="info-box">
            <div>
              <span className="info-box__label-text">Contact: </span>
              <span>{documentState.general.origin.contactName}</span>
            </div>
            <div>
              <span className="info-box__label-text">Phone: </span>
              <span>
                {formatPhoneNumber(documentState.general.origin.contactPhone)}
              </span>
            </div>
          </div>

          <div className="signature-header">Contact Printed Name</div>
          {documentState.pickup.contactPrinted ? (
            <div className="info-box">
              {documentState.pickup.contactPrinted}{" "}
            </div>
          ) : (
            <div className="signature-container"></div>
          )}

          <div className="signature-header">
            Contact Signature, {documentState.pickup.dateTime}
          </div>
          {documentState.pickup.contactSignature ? (
            documentState.pickup.contactSignature && (
              <div className="signature-container">
                <img
                  alt="contact-signature"
                  src={documentState.pickup.contactSignature}
                />
              </div>
            )
          ) : documentState.pickup.dateTime !== "Pending" ? (
            <div className="info-box">Picked up without signature</div>
          ) : (
            <div className="signature-container"></div>
          )}

          <div className="signature-header">
            Driver Signature, {documentState.pickup.dateTime}
          </div>
          <div className="signature-container">
            {documentState.pickup.dateTime !== "Pending" &&
              documentState.pickup.driver.signatureUrl && (
                <img
                  alt="driver-signature"
                  src={documentState.pickup.driver.signatureUrl}
                />
              )}

            {documentState.pickup.dateTime !== "Pending" &&
              !documentState.pickup.driver.signatureUrl && (
                <div className="info-box">No signature on file</div>
              )}
          </div>
        </div>
        <div className="info-grid-item section-container">
          <div className="section-header">Destination</div>

          <div className="info-box margin-bottom">
            <div>{documentState.general.destination.name}</div>
            <div>
              {documentState.general.destination.address} <br />
              {`${documentState.general.destination.city}, ${documentState.general.destination.state} ${documentState.general.destination.zip}`}
            </div>
          </div>

          <div className="info-box">
            <div>
              <span className="info-box__label-text">Contact: </span>
              <span>{documentState.general.destination.contactName}</span>
            </div>
            <div>
              <span className="info-box__label-text">Phone: </span>
              <span>
                {formatPhoneNumber(
                  documentState.general.destination.contactPhone
                )}
              </span>
            </div>
          </div>

          <div className="signature-header">Contact Printed Name</div>
          {documentState.dropoff.contactPrinted ? (
            <div className="info-box">
              {documentState.dropoff.contactPrinted}
            </div>
          ) : (
            <div className="signature-container"></div>
          )}

          <div className="signature-header">
            Contact Signature, {documentState.dropoff.dateTime}
          </div>
          {documentState.dropoff.contactSignature ? (
            documentState.dropoff.contactSignature && (
              <div className="signature-container">
                <img
                  alt="contact-signature"
                  src={documentState.dropoff.contactSignature}
                />
              </div>
            )
          ) : documentState.dropoff.dateTime !== "Pending" ? (
            <div className="info-box">Dropped off without signature</div>
          ) : (
            <div className="signature-container"></div>
          )}

          <div className="signature-header">
            Driver Signature, {documentState.dropoff.dateTime}
          </div>
          <div className="signature-container">
            {documentState.dropoff.date !== "Pending" &&
              documentState.dropoff.driver.signatureUrl && (
                <img
                  alt="driver-signature"
                  src={documentState.dropoff.driver.signatureUrl}
                />
              )}

            {documentState.dropoff.date !== "Pending" &&
              !documentState.dropoff.driver.signatureUrl && (
                <div className="info-box">No signature on file</div>
              )}
          </div>
        </div>
      </div>

      {/* <!-- END INFO --> */}

      {/* <!-- BEGIN DRIVER NOTES --> */}
      <div className="section-container">
        <div className="section-header">Driver Notes</div>

        <div className="info-box">
          <div>{documentState[inspectionType].vehicleNotes}</div>
        </div>
      </div>
      {/* <!-- END DRIVER NOTES --> */}

      {/* <!-- BEGIN PHOTOS--> */}
      <div className="section-container photos-section-print">
        <div className="section-header">{`${capitalize(
          inspectionType
        )} Photos`}</div>

        <div className="photos-grid">{renderPhotogrid()}</div>
      </div>
      {/* <!-- END PHOTOS--> */}

      {/* <!-- BEGIN INSPECTION --> */}
      <div className="section-container inspection-section-print">
        <div className="section-header">{`${capitalize(
          inspectionType
        )} Inspection`}</div>

        {areThereTransitDamages(inspectionType) === false ? (
          <div className="info-box">
            <div>No damages reported while in transit</div>
          </div>
        ) : (
          <div className="diagrams-grid">
            <div className="diagram-grid-item">
              <div className="diagram-container">
                <div className="diagram-overlay">
                  <div className="diagram-overlay__row row">
                    <div className="diagram-overlay__section front-1">
                      <p className="diagram-overlay__section-number">1</p>
                    </div>
                    <div className="diagram-overlay__section front-2">
                      <p className="diagram-overlay__section-number">2</p>
                    </div>
                  </div>
                  <div className="diagram-overlay__row row">
                    <div className="diagram-overlay__section front-3">
                      <p className="diagram-overlay__section-number">3</p>
                    </div>
                    <div className="diagram-overlay__section front-4">
                      <p className="diagram-overlay__section-number">4</p>
                    </div>
                  </div>
                </div>

                <FrontDiagramImage />
              </div>

              <div className="diagram-damage-table-container">
                <div className="section-header section-header--extra-padding">
                  Front
                </div>

                <div className="damage-table row">
                  <div className="damage-table__col--labels col">
                    <div className="damage-table__item damage-table__item--label">
                      1
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      2
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      3
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      4
                    </div>
                  </div>

                  <div className="damage-table__col--data col">
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Front", 1)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Front", 2)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Front", 3)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Front", 4)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="diagram-grid-item">
              <div className="diagram-container">
                <div className="diagram-overlay">
                  <div className="diagram-overlay__row row">
                    <div className="diagram-overlay__section rear-1">
                      <p className="diagram-overlay__section-number">1</p>
                    </div>
                    <div className="diagram-overlay__section rear-2">
                      <p className="diagram-overlay__section-number">2</p>
                    </div>
                  </div>
                  <div className="diagram-overlay__row row">
                    <div className="diagram-overlay__section rear-3">
                      <p className="diagram-overlay__section-number">3</p>
                    </div>
                    <div className="diagram-overlay__section rear-4">
                      <p className="diagram-overlay__section-number">4</p>
                    </div>
                  </div>
                </div>

                <RearDiagramImage />
              </div>

              <div className="diagram-damage-table-container">
                <div className="section-header section-header--extra-padding">
                  Rear
                </div>

                <div className="damage-table row">
                  <div className="damage-table__col--labels col">
                    <div className="damage-table__item damage-table__item--label">
                      1
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      2
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      3
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      4
                    </div>
                  </div>

                  <div className="damage-table__col--data col">
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Rear", 1)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Rear", 2)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Rear", 3)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Rear", 4)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="diagram-grid-item">
              <div className="diagram-container">
                <div className="diagram-overlay">
                  <div className="diagram-overlay__row row">
                    <div className="diagram-overlay__section driverside-1">
                      <p className="diagram-overlay__section-number">1</p>
                    </div>
                    <div className="diagram-overlay__section driverside-2">
                      <p className="diagram-overlay__section-number">2</p>
                    </div>
                    <div className="diagram-overlay__section driverside-3">
                      <p className="diagram-overlay__section-number">3</p>
                    </div>
                    <div className="diagram-overlay__section driverside-4">
                      <p className="diagram-overlay__section-number">4</p>
                    </div>
                  </div>
                  <div className="diagram-overlay__row row">
                    <div className="diagram-overlay__section driverside-5">
                      <p className="diagram-overlay__section-number">5</p>
                    </div>
                    <div className="diagram-overlay__section driverside-6">
                      <p className="diagram-overlay__section-number">6</p>
                    </div>
                    <div className="diagram-overlay__section driverside-7">
                      <p className="diagram-overlay__section-number">7</p>
                    </div>
                    <div className="diagram-overlay__section driverside-8">
                      <p className="diagram-overlay__section-number">8</p>
                    </div>
                  </div>
                </div>

                <DriverDiagramImage />
              </div>

              <div className="diagram-damage-table-container">
                <div className="section-header section-header--extra-padding">
                  Driver Side
                </div>

                <div className="damage-table row">
                  <div className="damage-table__col--labels col">
                    <div className="damage-table__item damage-table__item--label">
                      1
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      2
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      3
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      4
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      5
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      6
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      7
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      8
                    </div>
                  </div>

                  <div className="damage-table__col--data col">
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Driverside", 1)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Driverside", 2)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Driverside", 3)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Driverside", 4)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Driverside", 5)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Driverside", 6)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Driverside", 7)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Driverside", 8)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="diagram-grid-item">
              <div className="diagram-container">
                <div className="diagram-overlay">
                  <div className="diagram-overlay__row row">
                    <div className="diagram-overlay__section passengerside-1">
                      <p className="diagram-overlay__section-number">1</p>
                    </div>
                    <div className="diagram-overlay__section passengerside-2">
                      <p className="diagram-overlay__section-number">2</p>
                    </div>
                    <div className="diagram-overlay__section passengerside-3">
                      <p className="diagram-overlay__section-number">3</p>
                    </div>
                    <div className="diagram-overlay__section passengerside-4">
                      <p className="diagram-overlay__section-number">4</p>
                    </div>
                  </div>
                  <div className="diagram-overlay__row row">
                    <div className="diagram-overlay__section passengerside-5">
                      <p className="diagram-overlay__section-number">5</p>
                    </div>
                    <div className="diagram-overlay__section passengerside-6">
                      <p className="diagram-overlay__section-number">6</p>
                    </div>
                    <div className="diagram-overlay__section passengerside-7">
                      <p className="diagram-overlay__section-number">7</p>
                    </div>
                    <div className="diagram-overlay__section passengerside-8">
                      <p className="diagram-overlay__section-number">8</p>
                    </div>
                  </div>
                </div>

                <PassengerDiagramImage />
              </div>

              <div className="diagram-damage-table-container">
                <div className="section-header section-header--extra-padding">
                  Passenger Side
                </div>

                <div className="damage-table row">
                  <div className="damage-table__col--labels col">
                    <div className="damage-table__item damage-table__item--label">
                      1
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      2
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      3
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      4
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      5
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      6
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      7
                    </div>
                    <div className="damage-table__item damage-table__item--label">
                      8
                    </div>
                  </div>

                  <div className="damage-table__col--data col">
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Passengerside", 1)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Passengerside", 2)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Passengerside", 3)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Passengerside", 4)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Passengerside", 5)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Passengerside", 6)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Passengerside", 7)}
                    </div>
                    <div className="damage-table__item damage-table__item--data">
                      {getDamageChips("Passengerside", 8)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div class="section-container damage-key">
          <div class="section-header">Damage Key</div>
          <div class="info-box half-width">
            <div>S: Scratch</div>
            <div>C: Crack</div>
            <div>D: Dent</div>
            <div>G: Gouge</div>
          </div>
        </div> */}
      </div>
      {/* <!-- END INSPECTION --> */}
      {/* <!-- BEGIN ADDITIONAL COMMENTS --> */}
      <div className="section-container">
        <div className="section-header">Additional Inspection Comments</div>

        <div className="info-box">
          <div>{documentState[inspectionType].loadNotes}</div>
        </div>
      </div>
      {/* <!-- END ADDITIONAL COMMENTS --> */}
    </div>
  );

  return bolDocument;
};

export default BOL;
