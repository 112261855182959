/* 
    initialState.general contains general info for the vehicle
    intitialState.pickup contains pickup specific info
    initialState.dropoff contains dropoff specific info

    used for the initial state of the BOL document data, 
    gets updated whenever switching tabs
*/

const initialState = {
  general: {
    loadId: "",
    loadStatus: 0,
    shipperName: "",
    vehicle: {
      year: "",
      make: "",
      model: "",
      type: "",
      color: "",
      vin: "",
      odometer: "",
    },
    driver: {
      company: "",
      companyPhone: "",
      usDot: "",
    },
    origin: {
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      contactName: "",
      contactPhone: "",
    },
    destination: {
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      contactName: "",
      contactPhone: "",
    },
  },

  pickup: {
    contactSignature: "",
    date: "",
    driverNotes: "",
    // photos: {
    //   front: "",
    //   frontDriverCorner: "",
    //   driverSide: "",
    //   rearDriverCorner: "",
    //   rear: "",
    //   rearPassengerCorner: "",
    //   passengerSide: "",
    //   frontPassengerCorner: "",
    //   odometer: "",
    //   additional: [],
    // },
    photos: [],
    inspection: {
      Front: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      Rear: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      Driverside: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      },
      Passengerside: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      },
    },
    driver: {
      name: "",
      phone: "",
      signatureUrl: "",
    },
  },

  dropoff: {
    contactSignature: "",
    date: "",
    driverNotes: "",
    // photos: {
    //   front: "",
    //   frontDriverCorner: "",
    //   driverSide: "",
    //   rearDriverCorner: "",
    //   rear: "",
    //   rearPassengerCorner: "",
    //   passengerSide: "",
    //   frontPassengerCorner: "",
    //   odometer: "",
    //   additional: [],
    // },
    photos: [],
    inspection: {
      Front: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      Rear: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      Driverside: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      },
      Passengerside: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      },
    },
    driver: {
      name: "",
      phone: "",
      signatureUrl: "",
    },
  },
};

export default initialState;
